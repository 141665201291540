import "es6-promise/auto";
import images from './images';
import lazysizes from 'lazysizes';
import Swiper from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        data: {
            'mobileClass': false,
            'activeSlideThumb': 0,
            'modal' : false,
            'showModal': false
        },
        methods: {
            toggleVideoModal: function (id) {
                document.getElementsByTagName("html")[0].classList.toggle("overflow-hidden");
                document.getElementById("js-video").classList.toggle("active");

                if(id) {
                    document.querySelector("[data-youtube-iframe='"+id+"']").contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                }
            },
            toggleArrangement: function (id) {
                let element = document.querySelector("[data-arrangement-id='"+id+"']");
                if(element.classList.contains('active')) {
                    element.querySelector('.arrangement__list').style.height = '0px';
                } else {
                    let amountOfLists = element.querySelectorAll('.arrangement__info').length;
                    let lengthOfOneArrangementInfo = 50;
                    let height = amountOfLists * lengthOfOneArrangementInfo;
                    element.querySelector('.arrangement__list').style.height = height + 'px';
                }

                element.classList.toggle("active");
            },
            openMobileNav: function() {
                this.mobileClass = !this.mobileClass;

                document.getElementsByTagName("html")[0].classList.toggle("overflow-hidden");
                document.getElementById("header").classList.toggle("active");
            },
            slideNext: function(id) {
                var headerslider = document.querySelector('.js-header-slider').swiper;
                headerslider.slideTo(id);
            },
            playVideo(index) {
                if(this.$refs["videoPlayer" + index]) {
                    this.$refs["videoPlayer" + index].play();
                }
            },
            pauseVideo(index) {
                if(this.$refs["videoPlayer" + index]) {
                    this.$refs["videoPlayer" + index].pause();
                }
            }
        },
        mounted() {
            require('./javascript');
            require('./parallax');

            let heroGridMain = document.querySelector('.hero__grid--main');
            var x = window.matchMedia("(max-width: 992px)")
            if(x.matches) {
                this.playVideo(1);
                this.playVideo(2);
                this.playVideo(3);
            } else if (heroGridMain) {
                // is there is a heroGridMain class, it means there is only 1 hero block.
                // if this is the case, we want to autoplay the video.
                this.playVideo(3);
            }

            if (document.querySelectorAll('.showOnLoad').length) {
                for (var i = 0; i < document.querySelectorAll('.showOnLoad').length; i++) {
                    document.querySelectorAll('.showOnLoad')[i].classList.remove('showOnLoad');
                }
            }

            var headerslider = new Swiper('.js-header-slider', {
                loop: false,
                autoplay: {
                    delay: 8000,
                },
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                }
            });
            headerslider.on('slideChange', function () {
                var headerslider = document.querySelector('.js-header-slider').swiper;
                vm.activeSlideThumb = headerslider.activeIndex;
            });
            var reviews = new Swiper('.js-review-slider', {
                loop: true,
                navigation: {
                    nextEl: '.swiper-review-next',
                    prevEl: '.swiper-review-prev',
                }
            });
            var matrixSlider = new Swiper('.js-matrix-slider', {
                loop: true,
                navigation: {
                    nextEl: '.swiper-matrix-next',
                    prevEl: '.swiper-matrix-prev',
                }
            });
            var arrangementSlider = new Swiper('.js-arrangement-slider', {
                slidesPerView: 1,
                spaceBetween: 8,
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-arrangement-next',
                    prevEl: '.swiper-arrangement-prev',
                }
            });
            if(arrangementSlider.$el !== undefined) {
                arrangementSlider.slideTo(1, false,false);
            }

            var arrangementSlider1 = new Swiper('.js-arrangement-slider1', {
                slidesPerView: 1,
                spaceBetween: 8,
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-arrangement-next1',
                    prevEl: '.swiper-arrangement-prev1',
                }
            });
            if(arrangementSlider1.$el !== undefined){
                arrangementSlider1.slideTo(1, false,false);
            }

            var arrangementSlider2 = new Swiper('.js-arrangement-slider2', {
                slidesPerView: 1,
                spaceBetween: 8,
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-arrangement-next2',
                    prevEl: '.swiper-arrangement-prev2',
                }
            });
            if(arrangementSlider2.$el !== undefined) {
                arrangementSlider2.slideTo(1, false,false);
            }

        }
    });
};
// Execute async function
main().then( (value) => {

});
