/* global $, openTab */

/**
 * Utility to wrap the different behaviors between W3C-compliant browsers
 * and IE when adding event handlers.
 *
 * @param {Object}      element Object on which to attach the event listener.
 * @param {string}      type A string representing the event type to listen for
 *                      (e.g. load, click, etc.).
 * @param {function()}  callback The function that receives the notification.
 */

function addListener(element, type, callback) {
    if (element.addEventListener) {
        element.addEventListener(type, callback);
    } else if (element.attachEvent) {
        element.attachEvent('on' + type, callback);
    }
}

function renderVideo() {
    var video = document.getElementsByClassName('videodefer')[0];
    if(video) {
        var sources = video.getElementsByTagName('source');
        if(sources && sources.length) {
            sources[0].setAttribute('src', sources[0].getAttribute('data-src'));
            video.load();
        }
    }
};

window.onload = function() {
    var ulHasChildren = document.getElementsByClassName('menu__list');
    for (var i = 0; i < ulHasChildren.length; i++) {
        if (ulHasChildren[i].childNodes.length === 2) {
            ulHasChildren[i].classList.add('has-children');
        }
    }

    var menuChildToggle = document.getElementsByClassName('has-children');
    for (var k = 0; k < menuChildToggle.length; k++) {
        menuChildToggle[k].addEventListener('click', (function(k) {
            return function() {
                var menu = menuChildToggle[k].childNodes;
                if (menuChildToggle[k].classList) {
                    menuChildToggle[k].classList.toggle('open-parent');
                    menu[1].classList.toggle('open');
                }
            };

        })(k), false);
    }
};

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

(function() {
    var faqItem = document.getElementsByClassName('faq__item');
    for (var k = 0; k < faqItem.length; k++) {
        faqItem[k].addEventListener('click', (function (k) {
            return function () {
                if (faqItem[k].classList) {
                    faqItem[k].classList.toggle('active');
                }
            };
        })(k), false);
    }
})();

(() => {
    var tabLink = document.querySelectorAll("[data-tabLink]");
    var tabContent = document.querySelectorAll("[data-tabContent]");
    for (let k = 0; k < tabLink.length; k++) {
        tabLink[k].addEventListener('click', () => {
            for (var l = 0; l < tabLink.length; l++) {
                tabLink[l].classList.remove('active');
            }
            tabLink[k].classList.add('active');
            for (let i = 0; i < tabContent.length; i++) {
                var tabLinkAttr = tabLink[k].getAttribute("data-tabLink");
                var tabContentAttr = tabContent[i].getAttribute("data-tabContent");
                tabContent[i].style.display = "none";
                if (tabContentAttr === tabLinkAttr) {
                    tabContent[i].style.display = "flex";
                }
            }
        });
    }
})();
